import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import * as echarts from "echarts";
// import 'reset.css'
// import './assets/fonticon/iconfont.css'
// 将自动注册所有组件为全局组件
// import dataV from '@jiaminghi/data-view'
import router from './router'
import methods from '../src/utils/methods.js'
import '../src/utils/dialog.js'
import '../src/assets/fonticon/iconfont.css'


//iconfont
import '../src/assets/fonts/iconfont.css'


// import ElementUI from 'element-ui'


const $message = option => {
	console.log(option)
	return ElementUI.Message({
		...option,
		offset: 80
	})
}

['success', 'warning', 'info', 'error'].forEach(type => {
	$message[type] = option => {
		if (typeof option === 'string') {
			option = {
				message: option,
				offset: 80
			}
		}
		option.type = type
		return ElementUI.Message(option)
	}
})
Vue.use(ElementUI)
Vue.prototype.$message = $message
// import myTitle from './components/my-title.vue'
// import './utils/flexilb'

Vue.use(ElementUI);
// Vue.use(dataV)
// Vue.component('my-title',myTitle)
// Vue.prototype.$echarts = echarts;
Vue.prototype.$myweb = methods

Vue.config.productionTip = false


// router.beforeEach((to, from, next) => {
// 	var token = localStorage.getItem('token') //存储token
// 	console.log(9090, to);
// 	console.log(9090, from);
// 	console.log(9090, next);
// 	if (to.name==) {
// 		// document.title = to.meta.name;//路由中设置页面标题
// 		if (token || to.meta.nacy =='true') { //to.meta.nacy=='true'这里面的nacy就是自己在路由中设置的//不参与token验证的页面参数变量这个变量可以自己定义
// 			next()
// 		} else {
// 			if (to.path == '/login') {
// 				next()
// 			} else {
// 				next('/login')
// 			}
// 		}
// 	}
// })


new Vue({
	el: '#app',
	router,
	render: h => h(App),
}).$mount('#app')