import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);
const router = new VueRouter({
  routes: [
    {
        path:'/',
		name:'/',
        component:()=>import('../views/home/index.vue')
    },
    {
      path:'/organization',
	  name:'/organization',
      component:()=>import('../views/organization/indexs.vue')
    },
	{
	  path:'/details',
	  name:'/details',
	  component:()=>import('../views/details/details.vue')
	},
	{
	  path:'/login',
	  name:'/login',
	  component:()=>import('../views/login/loginindex.vue')
	},
    // {
    //   path:'/css',
    //   component:()=>import('../views/index.vue')
    // },
    // {
    //   path:'/satrt',
    //   component:()=>import('../views/demo/index.vue')
    // }
  ],
  mode: 'hash',
  // history
  // hash
  base: process.env.BASE_URL,
});

export default router;